import { createApp } from "vue";
import App from "./App.vue";

import "./index.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import router from "./router";

const firebaseConfig = {
  apiKey: "AIzaSyDcl-qaQh86An4Foj4aixMREqQ2dT6fOGg",
  authDomain: "qrcode-iffen.firebaseapp.com",
  projectId: "qrcode-iffen",
  storageBucket: "qrcode-iffen.appspot.com",
  messagingSenderId: "990996681489",
  appId: "1:990996681489:web:2950730772fb9bb01332b0",
  measurementId: "G-PX2RN5XTB8",
};

firebase.initializeApp(firebaseConfig);


export const db = firebase.firestore();

const app = createApp(App);

app.use(router);

app.mount("#app");
