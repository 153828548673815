import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: () => import("../pages/login.vue") },
    {
      path: "/dashboard",
      component: () => import("../pages/dashboard.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/pratique-rating",
      component: () => import("../pages/PratiqueRatingForm.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/student-list",
      component: () => import("../pages/StudentNoteList.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/student-notes",
      component: () => import("../pages/StudentSpaceNoteList.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/student-archive-notes",
      component: () => import("../pages/archiveStudentNote.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/student-space",
      component: () => import("../pages/StudentSpace.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/student-dashboard",
      component: () => import("../pages/StudentDashboard.vue"),
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.meta.requiresAuth) {
    if (token) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
